<template>
  <div
    class="user-balance white-color-bg radius-14 p-y-26 p-x-20 d-flex justify-content-between align-items-center"
  >
    <div
      class="user-details d-flex justify-content-start align-items-center gap-18"
    >
      <div class="img-container">
        <img
          class="radius-full"
          src="../../../../assets/images/profile/euro-icon.png"
          alt=""
        >
      </div>

      <div>
        <div class="d-flex flex-column">
          <p class="fs-16 light-gray-color-text mb-2 font-weight-bold">
            {{ $t('account.credit') }}
          </p>
          <div class="gap-10 d-flex justify-content-center align-items-center">
            <p class="fs-30 green-color-text font-weight-bold">
              {{ userData.users_balance }}
            </p>
            <p class="fs-16 light-gray-color-text font-weight-bold">
              {{ $t('account.egp') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="dark-gray-color-text px-md-5 px-0 fs-16">
        {{ $t('account.text2') }}
      </p>
    </div>
    <div>
      <base-button
        :text="$t('account.addBalance')"
        is-medium
        bg-color-class="black-color-bg"
        fixed-width="150px"
      />

      <router-link
        to="/"
        class="light-gray-color-text text-center d-block m-t-18"
      >
        {{ $t('account.preTransfer') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Balance',
  data () {
    return {}
  },
  computed: {
    userData () {
      return this.$store.getters['Globals/userInfo']
    }
  }
}
</script>

<style lang="scss" scoped>
@import './balance';
</style>
