<template>
  <div class="welcome-user white-color-bg radius-14 p-y-30 p-x-20">
    <div class="row align-items-center">
      <div class="col-8 order-1 order-md-1 col-md-4 col-lg-4">
        <div
          class="user-details d-flex justify-content-start align-items-center gap-18"
        >
          <div class="img-container">
            <img
              class="radius-full"
              :src="userData.users_picture"
              alt=""
            >

            <p>{{ $t('account.membership') }}</p>
          </div>

          <div>
            <span class="fs-14 dark-gray-color-text">
              #{{ userData.users_id }}
            </span>
            <p class="bold-font black-color-text fs-18">
              {{ $t('home.title') }} {{ userData.users_fname }}!
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 order-3 order-md-2 col-md-5 col-lg-6">
        <p class="fs-16 dark-gray-color-text descriprion">
          {{ $t('account.text') }}
        </p>
      </div>

      <div class="col-4 order-2 order-md-3 col-md-3 col-lg-2">
        <router-link
          :to="{ name: 'account-details' }"
          class="main-color-text fs-14"
        >
          {{ $t('account.editAccount') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import VueEasyPieChart from 'vue-easy-pie-chart'
import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'

export default {
  name: 'WelcomeUser',
  components: { VueEasyPieChart },
  data () {
    return {}
  },
  computed: {
    userData () {
      return this.$store.getters['Globals/userInfo']
    }
  }
}
</script>

<style lang="scss" scoped>
@import './welcome-user';
</style>
