<template>
  <div class="user-total-points white-color-bg radius-14 p-x-20">
    <div class="row align-items-center justify-content-between p-y-30 p-x-20">
      <div class="col-md-6 col-lg-2 mb-3">
        <div class="d-flex gap-30 justify-content-start align-items-center">
          <div class="img-container">
            <img
              src="../../../../assets/images/profile/user-total-points.png"
              alt=""
            >
          </div>

          <div>
            <div class="d-flex flex-column">
              <p class="fs-16 light-gray-color-text mb-2 font-weight-bold">
                {{ $t('account.yourPoints') }}
              </p>
              <div
                class="gap-10 d-flex justify-content-center align-items-center"
              >
                <p class="fs-30 purple-color-text font-weight-bold">
                  {{ $t('account.yourMony') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-3">
        <p class="dark-gray-color-text px-md-2 px-0 fs-16">
          {{ $t('account.text3') }}
        </p>
      </div>
      <div class="col-md-6 col-lg-2 mb-3">
        <base-button
          :text="$t('account.changePoints')"
          is-medium
          bg-color-class="purple-color-bg"
          fixed-width="150px"
        />
      </div>
      <div class="col-md-5">
        <h3 class="mb-3">
          {{ $t('account.getPoints') }}
        </h3>
        <ul class="row">
          <li
            class="col-12 col-md-6 light-gray-color-text d-flex align-items-center gap-6 mb-4 font-weight-bold"
          >
            <CheckIcon class="cursor-pointer green-color-text" />
            {{ $t('account.follow') }}
          </li>

          <li
            class="col-12 col-md-6 light-gray-color-text d-flex align-items-center gap-6 mb-4 font-weight-bold"
          >
            <CheckIcon class="cursor-pointer green-color-text" />
            {{ $t('account.follow') }}
          </li>
          <li
            class="col-12 col-md-6 light-gray-color-text d-flex align-items-center gap-6 mb-4 font-weight-bold"
          >
            <CheckIcon class="cursor-pointer" />
            {{ $t('account.follow') }}
            <span class="purple-color-text fs-14">
              {{ $t('account.point1') }}</span>
          </li>

          <li
            class="col-12 col-md-6 light-gray-color-text d-flex align-items-center gap-6 mb-4 font-weight-bold"
          >
            <CheckIcon class="cursor-pointer green-color-text" />
            {{ $t('account.follow') }}

            <!-- <span class="purple-color-text fs-14">10 نقاط</span> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '../../../icons/CheckIcon'
export default {
  name: 'UserTotalPoints',
  components: { CheckIcon }
}
</script>

<style lang="scss" scoped>
@import './user-total-points.scss';
</style>
