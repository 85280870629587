<template>
  <div class="container">
    <div class="row statistics white-color-bg radius-14 p-y-26 p-x-20">
      <div
        v-if="currentTab"
        class="col-12 col-sm-5 donut-chart-block block mb-1"
      >
        <div class="donut-chart mb-5">
          <div
            id="part1"
            class="portion-block"
          >
            <div class="circle" />
          </div>
          <div
            id="part2"
            class="portion-block"
          >
            <div class="circle" />
          </div>
          <p class="d-flex flex-column center">
            <span
              class="mb-2 fs-14 light-gray-color-text font-weight-bold span-line-height"
            >{{ $t('account.saved') }}</span>
            <span class="fs-40 mb-1 font-weight-bold">
              {{ userData.saved_amount }}
            </span>
            <span
              class="fs-14 light-gray-color-text font-weight-bold span-line-height"
            >{{ $t('account.egp') }}</span>
          </p>
        </div>
        <div class="mb-1 text-center font-weight-bold">
          <span class="dot spent-dot" /> {{ $t('account.spend') }}
          <span class="spent-money">{{ userData.spent_amount }}</span>
          {{ $t('account.egp') }}
        </div>
        <div class="mb-1 text-center font-weight-bold">
          <span class="dot save-dot" /> {{ $t('account.saved') }}
          <span class="save-money">{{ userData.saved_amount }}</span>
          {{ $t('account.egp') }}
        </div>
      </div>
      <div
        v-else
        class="col-12 col-sm-5 donut-chart-block block mb-1"
      >
        <div class="donut-chart mb-5">
          <div
            id="part1"
            class="portion-block"
          >
            <div class="circle" />
          </div>
          <div
            id="part2"
            class="portion-block"
          >
            <div class="circle" />
          </div>
          <p class="d-flex flex-column center">
            <span
              class="mb-2 fs-14 light-gray-color-text font-weight-bold span-line-height"
            >{{ $t('account.saved') }}</span>
            <span class="fs-40 mb-1 font-weight-bold">
              {{ userData.monthly_saved_amount }}
            </span>
            <span
              class="fs-14 light-gray-color-text font-weight-bold span-line-height"
            >{{ $t('account.egp') }}</span>
          </p>
        </div>
        <div class="mb-1 text-center font-weight-bold">
          <span class="dot spent-dot" /> {{ $t('account.spend') }}
          <span class="spent-money"> {{ userData.monthly_spent_amount }} </span>
          {{ $t('account.egp') }}
        </div>
        <div class="mb-1 text-center font-weight-bold">
          <span class="dot save-dot" /> {{ $t('account.saved') }}
          <span class="save-money"> {{ userData.monthly_saved_amount }} </span>
          {{ $t('account.egp') }}
        </div>
      </div>
      <div class="col-12 col-sm-7 mb-4">
        <p class="time-switch">
          <span
            v-for="(time, index) in times"
            :key="index"
            class="this-month fs-14"
            :class="{ active: currentTab === index }"
            @click="currentTab = index"
          >
            <span>{{ $t(time.name) }}</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Statistics',
  data () {
    return {
      times: [{ name: 'account.month' }, { name: 'account.time' }],
      currentTab: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters['Globals/userInfo']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import './statistics';
</style>
