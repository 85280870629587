<template>
  <div
    class="user-points white-color-bg radius-14 p-y-26 p-x-20 d-flex justify-content-between align-items-center"
  >
    <div class="points m-t-14">
      <div class="points-progress">
        <span class="min-points fs-14 font-weight-600 black-color-text">{{
          $t('account.startPoint')
        }}</span>
        <div
          class="prgress-bar"
          style="width: 40%"
        >
          <span
            class="main-color-bg white-color-text p-x-10 p-y-6 radius-14 fs-14"
          >{{ $t('account.endPoint') }}</span>
        </div>
        <span class="max-points fs-14 font-weight-600 black-color-text">{{
          $t('account.point1')
        }}</span>
      </div>
      <p class="light-gray-color-text font-weight-600 fs-16 m-t-14">
        {{ $t('account.point2') }}
        <span class="main-color-text font-weight-600">
          {{ $t('account.platinum') }}</span>
      </p>
    </div>

    <div class="actions">
      <base-button
        :text="$t('account.benefits')"
        is-medium
        bg-color-class="main-color-bg"
        fixed-width="175px"
      />

      <router-link
        to="/"
        class="main-color-text text-center d-block m-t-18"
      >
        {{ $t('account.levels') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserPoints'
}
</script>

<style lang="scss" scoped>
@import './user-points';
</style>
