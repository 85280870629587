<template>
  <div class="w-100">
    <MyAccount />
  </div>
</template>

<script>
import MyAccount from '@/components/profile/my-account/Index.vue'
export default {
  name: 'MyAccountPage',
  components: { MyAccount }
}
</script>
