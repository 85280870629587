<template>
  <div class="my-account">
    <div class="row">
      <div class="col-12">
        <WelcomeUser />
      </div>

      <div class="col-xl-7">
        <UserPoints class="m-t-30" />
        <Balance class="m-t-30" />
      </div>

      <div class="col-xl-5">
        <Statistics class="m-t-30" />
      </div>

      <div class="col-12 mt-4">
        <UserTotalPoints />
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeUser from './welcome-user/Index.vue'
import UserPoints from './user-points/Index.vue'
import Statistics from './statistics/Index.vue'
import Balance from './balance/Index.vue'
import UserTotalPoints from './user-total-points/Index.vue'
export default {
  name: 'MyAccount',
  components: { WelcomeUser, UserPoints, Statistics, Balance, UserTotalPoints }
}
</script>
